import { graphql, useStaticQuery } from "gatsby";
import { useIntl } from "gatsby-plugin-react-intl";
import React from "react";
import Breadcrumbs from "../components/Breadcrumbs";
import siteTutorialsData from "../data/tutorials/site_tutorials";
import { SECTION_COLOR } from "../landingnewcomponents/Section/Landing";
import { TITLE_COLORS } from "../landingnewcomponents/Title/Landing";
import Layout from "../layouts/layout";
import ContactUsSection from "../sections/contact-us";
import TutorialsSection from "../sections/tutorials";
import "./../common/styles.scss";
import { normalizePost } from "../helpers/normalizePost";


const TutorialsPage = (props) => {
  const { formatMessage } = useIntl();

  const tutorialsPostsFromWordpressRaw = useStaticQuery(graphql`
      {
          tutorials: allWpPost(filter: {categories: {nodes: {elemMatch: {databaseId: {eq: 1}}}}}) {
              nodes {
                  title
                  link
                  uri
                  content
                  databaseId
                  date
                  postsFields {
                      featuredImage {
                          databaseId
                          mediaItemUrl
                      }
                      youtubeUrl
                  }
              }
          }
      }
  `);
  const tutorialsPostsFromWordpress = tutorialsPostsFromWordpressRaw.tutorials.nodes.map(entry => {
    const tutorial = normalizePost(entry);
    if (entry.postsFields?.youtubeUrl) {
      console.log(entry.postsFields.youtubeUrl);
      return { ...tutorial, linkSrc: entry.postsFields.youtubeUrl || tutorial.linkSrc };
    } else {
      return tutorial;
    }

  });

  return (
    <Layout location={props.location}>
      <TutorialsSection
        title={formatMessage({ id: "pages.tutorials.sections.main.title", defaultMessage: "Tutorials" })}
        sectionColor={SECTION_COLOR.White}
        titleColor={TITLE_COLORS.LightBlue}
        seeAllUrl="/tutorials/"
        data={tutorialsPostsFromWordpress}
      >
        <Breadcrumbs
          items={[{ title: "Viewst", url: "/" }, { title: "Learn", url: "/learn/" }, {
            title: "Tutorials",
            url: "/tutorials/"
          }]}
        />
      </TutorialsSection>
      <ContactUsSection />
    </Layout>
  );
};

export default TutorialsPage;
