module.exports = [
    {
        title: "How to create animated banner set in 5 min",
        desc: "How to create a banner? Specs of Ad networks. Design banner tips from the Pros",
        imgSrc: "/images/landings/tutorials/tutorial-1@2x.png",
        linkSrc: "https://www.youtube.com/watch?v=Oi6qhWuez5M",
    },
    {
        title: "How to animate banners in minutes",
        desc: "How to create a banner? Specs of Ad networks. Design banner tips from the Pros",
        imgSrc: "/images/landings/tutorials/tutorial-2@2x.png",
        linkSrc: "https://www.youtube.com/watch?v=wFxcMP_pEig",
    },
    {
        title: "How to create a banner? Specs of Ad networks. Design banner tips from the Pros",
        desc: "How to create a banner? Specs of Ad networks. Design banner tips from the Pros",
        imgSrc: "/images/landings/tutorials/tutorial-3@2x.png",
        linkSrc: "#",
    },
    {
        title: "How to create animated banner set in 5 min",
        desc: "How to create a banner? Specs of Ad networks. Design banner tips from the Pros",
        imgSrc: "/images/landings/tutorials/tutorial-1@2x.png",
        linkSrc: "https://www.youtube.com/watch?v=Oi6qhWuez5M",
    },
    {
        title: "How to animate banners in minutes",
        desc: "How to create a banner? Specs of Ad networks. Design banner tips from the Pros",
        imgSrc: "/images/landings/tutorials/tutorial-2@2x.png",
        linkSrc: "https://www.youtube.com/watch?v=wFxcMP_pEig",
    },
    {
        title: "How to create a banner? Specs of Ad networks. Design banner tips from the Pros",
        desc: "How to create a banner? Specs of Ad networks. Design banner tips from the Pros",
        imgSrc: "/images/landings/tutorials/tutorial-3@2x.png",
        linkSrc: "#",
    },
    {
        title: "How to create animated banner set in 5 min",
        desc: "How to create a banner? Specs of Ad networks. Design banner tips from the Pros",
        imgSrc: "/images/landings/tutorials/tutorial-1@2x.png",
        linkSrc: "https://www.youtube.com/watch?v=Oi6qhWuez5M",
    },
    {
        title: "How to animate banners in minutes",
        desc: "How to create a banner? Specs of Ad networks. Design banner tips from the Pros",
        imgSrc: "/images/landings/tutorials/tutorial-2@2x.png",
        linkSrc: "https://www.youtube.com/watch?v=wFxcMP_pEig",
    },
    {
        title: "How to create a banner? Specs of Ad networks. Design banner tips from the Pros",
        desc: "How to create a banner? Specs of Ad networks. Design banner tips from the Pros",
        imgSrc: "/images/landings/tutorials/tutorial-3@2x.png",
        linkSrc: "#",
    },
    {
        title: "How to create animated banner set in 5 min",
        desc: "How to create a banner? Specs of Ad networks. Design banner tips from the Pros",
        imgSrc: "/images/landings/tutorials/tutorial-1@2x.png",
        linkSrc: "https://www.youtube.com/watch?v=Oi6qhWuez5M",
    },
    {
        title: "How to animate banners in minutes",
        desc: "How to create a banner? Specs of Ad networks. Design banner tips from the Pros",
        imgSrc: "/images/landings/tutorials/tutorial-2@2x.png",
        linkSrc: "https://www.youtube.com/watch?v=wFxcMP_pEig",
    },
    {
        title: "How to create a banner? Specs of Ad networks. Design banner tips from the Pros",
        desc: "How to create a banner? Specs of Ad networks. Design banner tips from the Pros",
        imgSrc: "/images/landings/tutorials/tutorial-3@2x.png",
        linkSrc: "#",
    },
    {
        title: "How to create animated banner set in 5 min",
        desc: "How to create a banner? Specs of Ad networks. Design banner tips from the Pros",
        imgSrc: "/images/landings/tutorials/tutorial-1@2x.png",
        linkSrc: "https://www.youtube.com/watch?v=Oi6qhWuez5M",
    },
    {
        title: "How to animate banners in minutes",
        desc: "How to create a banner? Specs of Ad networks. Design banner tips from the Pros",
        imgSrc: "/images/landings/tutorials/tutorial-2@2x.png",
        linkSrc: "https://www.youtube.com/watch?v=wFxcMP_pEig",
    },
    {
        title: "How to create a banner? Specs of Ad networks. Design banner tips from the Pros",
        desc: "How to create a banner? Specs of Ad networks. Design banner tips from the Pros",
        imgSrc: "/images/landings/tutorials/tutorial-3@2x.png",
        linkSrc: "#",
    },
]
